import { Method } from '@entities/Method';
import * as ajaxHelpers from './ajax-helpers';

interface IDevice {
    /** Check if the current device is recognised as mobile (or tablet). */
    isMobile: boolean;
    /** Check if the current device supports touch events. */
    supportsTouch: boolean;
}

interface IBrowser {
    /** Check if the current browser is Edge. */
    isEdge: boolean;
    /** Check if the current browser is IE 11 (condolences if it is). */
    isIE11: boolean;
}

/**
 * List of browser flags.
 */
export const browser: IBrowser = ((ua: string) => {
    const isEdge = /Edge/.test(ua);
    const isIE11 = /Trident\/7.0/.test(ua);

    return { isEdge, isIE11 };
})(navigator.userAgent);

/**
 * Switch to a different version of the site. Usually used for multi-currency applications.
 * @param id  The ID of the site to switch to.
 * @param redirect  The base URL of the site the user will be redirected to.
 */
export function changeSite(id: number, redirect: string): void {
    const data = { siteID: id || 1 };

    ajaxHelpers.xhr<boolean>(Method.POST, '/changesite', data).then(allowRedirect => {
        if (allowRedirect) {
            window.location.replace(redirect);
        } else {
            alert('Please empty your basket before switching to an alternative version of this site.');
        }
    });
}

/**
 * List of device flags.
 */
export const device: IDevice = (() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Modernizr method is flaky. Consider better logic.
    const supportsTouch = 'Modernizr' in window && (Modernizr.touchevents || false);

    return { isMobile, supportsTouch };
})();

/**
 * Check if picturefill exists (note: it's loaded asynchronously).
 */
export function picturefillLoaded(): boolean {
    return 'picturefill' in window && typeof picturefill !== 'undefined';
}

/**
 * Check if the current device supports passive event listeners.
 * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 */
export const passiveSupported = ((): boolean => {
    let isSupported = false;

    try {
        const options = Object.defineProperty({}, 'passive', {
            get: () => {
                isSupported = true;
            }
        });

        window.addEventListener('test', options, options);
        window.removeEventListener('test', options, options);
    } catch (err) {
        isSupported = false;
    }

    return isSupported;
})();

/**
 * Utilise passive event listeners if the browser supports them. *cough IE11 cough*.
 */
export const usePassiveIfSupported = passiveSupported ? { passive: true } as EventListenerOptions : false;
