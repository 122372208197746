import * as $ from 'jquery';

/**
 * Initialise mobile navigation using the Mmenu plugin.
 */
export function init(): void {
    const options: JQueryMmenu.Options = {
        extensions: ['pageshadow']
    };

    const config: JQueryMmenu.Configurations = {
        offCanvas: {
            pageSelector: '.global-wrapper',
            wrapPageIfNeeded: false
        }
    };

    const nav = $('.nav-main-mobile');
    const toggleButton = $('.toggle-mobile-nav');
    const api = nav.mmenu(options, config).data('mmenu');
    const firstPanel = $(api.getInstance().$pnls[0].firstChild);
    const searchInput = firstPanel.find('.search-mobile input[type=text]');
    const hasSearch = searchInput.length

    api.bind('open', () => {
        toggleButton.addClass('active');
    });

    api.bind('close', () => {
        toggleButton.removeClass('active');

        if (hasSearch) {
            searchInput.blur();
        }
    });

    toggleButton.on('click', (event: JQuery.Event) => {
        event.preventDefault();
        api.open();
    });

    // Hide the nav bar on the first panel so that we can plonk a search bar there instead.
    if (hasSearch) {
        firstPanel.find('.mm-navbar').addClass('hidden');
    }
}
