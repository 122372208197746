import * as siteHelpers from '@functions/site-helpers';
import * as $ from 'jquery';

/**
 * Initialise desktop/tablet navigation, with different behaviour on touch-enabled devices.
 */
export function init(): void {
    const nav = $('.main-nav .nav-items');
    const items = nav.find('li.has-children').toggleClass('has-children is-dropdown').find('> a');
    

    if (siteHelpers.device.supportsTouch) {
        const overlay = $('<div class="main-nav-overlay"></div>').appendTo('.global-wrapper');

        items.on('click', (event: JQuery.Event) => {
            event.preventDefault();

            $(event.currentTarget).parent().siblings().removeClass('open').end().toggleClass('open');

            if (nav.find('li.open').length) {
                overlay.show();
            } else {
                overlay.hide();
            }
        });

        overlay.on('click', () => {
            items.parent().removeClass('open');
            overlay.hide();
        });
    } else {
        const options: HoverIntent.IOptions = {
            over: (event: JQuery.Event) => {
                $(event.currentTarget).siblings().removeClass('open').end().addClass('open');
            },
            out: (event: JQuery.Event) => {
                $(event.currentTarget).removeClass('open');
            },
            timeout: 250
        };

        items.parent().hoverIntent(options);
    }
}
