import * as siteHelpers from '@functions/site-helpers';
import * as jQueryHelpers from './jquery-helpers';

const mimicSmoothScroll = true;

function onMouseWheel(event: MouseWheelEvent): void {
    event.preventDefault();

    window.scrollTo(0, window.pageYOffset - event.wheelDelta);
}

function onMouseWheelSmooth(event: MouseWheelEvent): void {
    event.preventDefault();

    // Bear in mind that jQuery animate isn't known for performance, and IE11 doesn't support passive event listeners.
    $('html, body').stop(true, true).animate({
        scrollTop: `${window.pageYOffset - event.wheelDelta}px`
    }, 400, jQueryHelpers.getTimingFunction('easeOutExpo'));
}

/**
 * Override the native smooth scroll functionality in IE11, eliminating some nasty side-effects, namely the rendering issue
 * with the 'background-attachment: fixed' style. This isn't perfect; it doesn't help with trackpad or keyboard scrolling.
 * Pure CSS fixes are available (see: https://www.coffeecup.com/help/articles/solving-the-ie11-fixed-background-bug/),
 * however they stop scroll events firing and break Window properties - both of which we need.
 */
export function smoothScrollOverride(): void {
    if (siteHelpers.browser.isIE11) {
        document.body.addEventListener('mousewheel', mimicSmoothScroll ? onMouseWheelSmooth : onMouseWheel);
    }
}
