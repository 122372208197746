import * as $ from 'jquery';

/**
 * Check if an element has validation data associated with it.
 * @param element  The element to check.
 */
export function checkHasValidator(element: JQuery<HTMLElement>): boolean {
    const validator = element.data('validator');

    return typeof validator !== 'undefined';
}
