import * as siteHelpers from '@functions/site-helpers';
import * as $ from 'jquery';

/**
 * Initialise the site selector modal.
 */
export function init(): void {
    const modal = $('.modal-site-change');

    if (!!modal.length) {
        const siteButtons = $('.modal-site-change .modal-body ul a, .nav-main-mobile .mm-listview .site a');
        const toggleButton = $('.main-nav .summary-container');

        siteButtons.on('click', (event: JQuery.Event) => {
            const link = event.currentTarget as HTMLElement;

            event.preventDefault();
            siteHelpers.changeSite(+link.dataset.siteId, link.getAttribute('href'));
        });

        toggleButton.on('click', '.change-site', (event: JQuery.Event) => {
            event.preventDefault();
            modal.modal('show');
        });
    }
}
