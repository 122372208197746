﻿import * as $ from 'jquery';

/*
 * A plugin to disable buttons, display alternate text and show a loading animation whilst waiting for an action to complete.
 */

namespace ButtonLoadingState {
    enum ButtonType {
        Input = 'input',
        Button = 'button'
    }

    const defaultOptions: IButtonOptions = {
        message: 'One moment...',
        disable: true,
        showSpinner: false
    };

    // These methods rely on assignment of 'this' as a JQuery object and would fail if called in any other context.
    const methods = {
        show(this: JQuery<HTMLElement>, options?: IButtonOptions): typeof this {
            const settings: IButtonOptions = { ...defaultOptions, ...options };

            return this.each((index: number, element: Element) => {
                const $element = $(element);
                const elementType = element.tagName.toLowerCase();

                $element.attr('disabled', settings.disable ? 'disabled' : null);

                switch (elementType) {
                    case ButtonType.Input:
                        $element.attr({
                            'data-value': $element.val(),
                            'value': settings.message
                        });

                        if (settings.showSpinner) {
                            $element.after('<span class="ws-spinner"></span>');
                        }

                        break;
                    case ButtonType.Button:
                        $element.attr('data-value', $element.html());
                        $element.text(settings.message);

                        if (settings.showSpinner) {
                            $element.append('<span class="ws-spinner"></span>');
                        }

                        break;
                }
            });
        },
        hide(this: JQuery<HTMLElement>): typeof this {
            return this.each((index: number, element: Element) => {
                const $element = $(element);
                const elementType = element.tagName.toLowerCase();

                $element.attr('disabled', null);

                switch (elementType) {
                    case ButtonType.Input:
                        $element.attr('value', $element.data('value'));
                        $element.siblings('.ws-spinner').remove();
                        break;
                    case ButtonType.Button:
                        $element.html($element.data('value'));
                        break;
                }
            });
        }
    };

    export function init(): void {
        $.fn.extend({
            buttonLoadingState: function(this: JQuery, method: keyof typeof methods, options?: IButtonOptions): typeof this {
                const validElements = this.filter('input[type=submit], input[type=button], button');

                return !!validElements.length ? methods[method].apply(validElements, typeof options !== 'undefined' ? [options] : null) : this;
            }
        });
    }
}

ButtonLoadingState.init();
