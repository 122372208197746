import * as $ from 'jquery';

/**
 * Hook onto the submit event of any password reset forms.
 */
export function initPasswordResetForms(): void {
    const resetLink = $('.link-password-reset');

    if (resetLink.length) {
        resetLink.on('click', (event: JQuery.Event) => {
            event.preventDefault();

            const emailInputs = $('#Email, #GuestEmail');
            const derivedEmailAddress = !!emailInputs.length ? emailInputs.first().val() as string : '';

            window.location.replace(`/account/resetpassword/?email=${derivedEmailAddress}`);
        });
    }
}
