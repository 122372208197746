import '@components/jquery.button-loading-state';
import * as desktopNavigation from '@components/navigation/desktop';
import * as mobileNavigation from '@components/navigation/mobile';
import * as siteSelector from '@components/site-selector';
import * as accountHelpers from '@functions/account-helpers';
import * as formHelpers from '@functions/form-helpers';
import * as ieHelpers from '@functions/ie-helpers';
import * as $ from 'jquery';

// Disable Bootstrap transitions.
// This is set by Bootstrap on the domready event, so we need to do the same otherwise it'll be overwritten.
$(() => {
    $.support.transition = false;
});

// Initialise/call various global components and functions.
mobileNavigation.init();
desktopNavigation.init();
siteSelector.init();
accountHelpers.initPasswordResetForms();
ieHelpers.smoothScrollOverride();

// If some forms are submitted more than once (eg. User double clicks submit button) then errors can occur.
// In these cases we want to disable the submit button after it's clicked, UNLESS jQuery validate is bound and the form is invalid.
$('.form-submit-once').on('submit', (event: JQuery.Event) => {
    const form = $(event.currentTarget);
    const hasValidator = formHelpers.checkHasValidator(form);

    if (!hasValidator || (hasValidator && form.valid())) {
        form.find('input[type=submit]').prop('disabled', true);
    }
});

// Open external links in a new window.
$('a[rel^="external"]').on('click', (event: JQuery.Event) => {
    event.preventDefault();
    window.open((event.currentTarget as Element).getAttribute('href'));
});

$('.date-picker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true
});

// Run any queued jQuery scripts.
$.each(APP_EXTERNAL.queue, (i, f) => $(f));
