import { Method } from '@entities/Method';
import * as $ from 'jquery';

type XHRCallback = (request: JQueryXHR, status: string, error: string) => void;

// tslint:disable-next-line:no-console
const defaultAjaxErrorCallback: XHRCallback = (_, status, error): void => console.log(`XHR ERROR: ${error}, STATUS: ${status}`);

function generateQueryString(data: any): string {
    let output = '';

    if (!!data) {
        switch (typeof data) {
            case 'string':
                output = data; // Hopefully serialised!
                break;
            case 'object':
                output = $.param(data);
                break;
        }
    }

    return `${output}${output.length ? '&' : ''}IsAjaxRequest=true`;
}

function __xhr(method: Method, url: string, data: any, successCallback?: (data: any) => void, errorCallback?: XHRCallback): void {
    const options: JQuery.AjaxSettings = {
        cache: false,
        data: generateQueryString(data),
        type: Method[method],
        url: url
    };

    $.ajax(options).done(successCallback).fail(errorCallback || defaultAjaxErrorCallback);
}

/**
 * Make an XMLHttpRequest. Currently pipes through the JQuery.ajax() method. Maybe switch to the Fetch API in future?
 */
export async function xhr<T>(method: Method, url: string, data?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => __xhr(method, url, data || null, response => resolve(response), response => reject(response)));
}
